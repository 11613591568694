.App {
  background-color: white;
}

.Auth-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  /* flex-direction: column; */
}
.Auth-form-Password {
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 40vw;
  height: 40vh; */
  padding: 50px;
  /* flex-direction: column; */
}


.Auth-form {
  width: 420px;
  box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
  padding-top: 30px;
  padding-bottom: 20px;
  border-radius: 8px;
  background-color: white;
}

.Auth-form-content {
  padding-left: 12%;
  padding-right: 12%;
}

.Auth-form-title {
  text-align: center;
  margin-bottom: 1em;
  font-size: 24px;
  color: rgb(34, 34, 34);
  font-weight: 800;
}

label {
  font-size: 14px;
  font-weight: 600;
  color: rgb(34, 34, 34);
}

#datePicker_qouta {
  border: 1px solid #198754;
  height: 30px;
  width: 100%;
  padding-left: .5rem;
  border-radius: 6px;
  font-size: .875rem;
}

#datePicker_contract {
  border: 1px solid green;
  height: 30px;
  font-size: .875rem;
  width: 100%;
  padding-left: .5rem;
  border-radius: 6px;
}

.orgDetail__links-container {
  display: grid;
  gap: 20px;
  justify-content: flex-start;
  grid-template-columns: repeat(4, minmax(100px, 1fr));
}

.orgDetail__button {
  min-width: 100px;
  width: 100%;
  font-size: 22px;
  font-weight: 600;
}

#contract {
  color: black;
  text-decoration: none;
  display: inline-block;
  width: 100%;
  font-size: .875rem;
}

#qouta_sold {
  height: 500px;
  font-size: 14px;

}

.interactive_hover:hover {
  transition: .7;
  opacity: .7;
  cursor: pointer;
}

.link__asset {
  color: blue;
  cursor: pointer;
  background-color: black;
}

.link__asset:hover {
  text-decoration: underline;
}

@media screen and (max-width: 980px) {
  .orgDetail__button {
    font-size: 18px;
  }

}