.descriptions {
	background-color: #FFF;
	border-radius: 8px;
	padding: 28px 32px;
}

.table :global(.ant-table-cell)::before {
	width: 0 !important;

}

.table :global(.ant-table-cell) {
	padding: 8px !important;
	font-size: 14px;
	font-style: normal;
	color: #4B4F55 !important;

}

.table :global(.ant-table-cell):first-child {
	padding: 8px 8px 8px 16px !important;
}

.table th:global(.ant-table-cell) {
	font-size: 12px;
	text-transform: uppercase;
	color: #8090B8 !important;
	background-color: #FCFDFF !important;
}

.table :global(.ant-table-row):nth-child(2n) {
	background-color: #FFFFFF !important;
}

.table :global(.ant-table-row):nth-child(2n - 1) {
	background-color: #FCFDFF !important;
}

.icon {
	font-size: 20px;
	color: #8090B8;
}

.iconSistem {
	font-size: 20px;
}

.icon:nth-child(n) {
	padding-inline: 3px;
}

.icon:hover {
	color: #327FEF;
}

.table_header {
	display: block;
}

.table_header thead tr {
	position: sticky;
	top: 0;
	z-index: 100;
	/* Убедитесь, что шапка таблицы находится над другими элементами */
}

.table_header thead th {
	background-color: #fff;
	/* Цвет фона шапки, чтобы она не была прозрачной */
}


.versionColumnWrapper {
	display: flex;
	flex-direction: column;
}

.versionColumn {
	display: flex;
	gap: 12px;
	font-weight: 600;
}

.typeColumnWrapper {
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.spaceBetweenJustified {
	display: flex;
	justify-content: space-between;
}

.flexEndJustified {
	display: flex;
	justify-content: flex-end;
}

.centerAlignedItemsWithGap {
	display: flex;
	align-items: center;
	gap: 10px
}

.centerAlignedItems {
	display: flex;
	align-items: center
}

.secondaryText {
	color: #7A7D86;
}

.secondaryTextWithMargin {
	color: #7A7D86;
	margin-left: 7px
}

.primaryText {
	color: #333333;
}

.downloadButton {
	background: none;
	padding: 0px;
	margin: 0px;
}

.tableWrapper {
	height: 400px;
	width: 100%;
	overflow-y: auto;
	background: white;
	position: relative
}

.downloadFileWrapper {
	z-index: 2;
	width: 100%;
	height: 400px;
	background: rgba(255, 255, 255, .9);
	position: absolute;
	padding: 10px
}

.fileDownloader {
	display: flex;
	gap: 10;
	flex-direction: column;
	background: #F6F8FD;
	padding: 10px;
	border-radius: 8;
	margin-bottom: 20px;
}

.fileDownloaderProgressBarWrapper {
	display: flex;
	align-items: center;
	gap: 20;
	flex-direction: column
}

.successDownloadMessage {
	font-size: 20;
}

.lastEl {
	height: 20
}

.maxWidth {
	width: 100%
}

.newParagraph {
	margin: 0 0 5px;
	font-size: 14;
	font-weight: 600
}

.flexStartJustified {
	display: flex;
	flex-direction: column;
	gap: 12px;
	align-items: flex-start
}

.divider{
	margin: 5px 0 0;
}