.eula-list{
    line-height: 2rem;
    padding-left: 0;
    list-style: none;
}

.company-info{
    margin-top: 10vh;
    list-style: none;
    padding-left: 0;
}