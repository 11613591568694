.form :global(.ant-form-item-label) {
	padding: 0;
}

.form :global(.ant-form-item-label) label {
	color: #4B4F55;
	height: fit-content !important;
}

.form :global(.ant-form-item-control) input {
	border-radius: 4px !important;
	height: 48px;
	padding: 12px 16px;
}

.form :global(.ant-input-password) input {
	height: auto;
	padding: 0;
}

.form :global(.ant-input-password) {
	border-radius: 4px !important;
	height: 48px;
	padding: 12px 16px;
}

.form :global(.ant-form-item-control-input) {
	min-height: 20px !important;
}

.input :-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	-webkit-box-shadow: 0 0 0 30px white inset !important;
}