.menu {
	background-color: transparent;
}

.menu :global(.ant-menu-item) {
	width: 100%;
	height: 48px;
	display: flex;
	align-items: center;
	margin: 0;
	padding: 8px 32px;
	gap: 12px;
	border-radius: 0;
}
.menu :global(.ant-menu-item) > * {
	flex-shrink: 0;
}

.menu :global(.ant-menu-item-selected)::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 3px;
	height: 100%;
	background-color: white;
	border-radius: 0 3px 3px 0;
}

.link {
	text-decoration: none;
	color: #989AA5;
	width: 100%;
	height: 48px;
	display: flex;
	align-items: center;
	margin: 0;
	padding: 8px 24px;
	gap: 23px;
	font-weight: 400;

}
.link:hover{
	text-decoration: none;
	color: white;

}



