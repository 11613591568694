.loaderPosition {
    top: 30%;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    width: 50px;
    /* width: 95%;
    max-width: 100%; */
    height: 100px;
    display: flex;
    justify-content: center;
    z-index: 100000000;
    /* display: none; */
}

.loaderTitle {
    position: absolute;
    top: 35px;
    left: 20px;
    font-weight: bold;
}

.loader {
    /* border: 10px solid #f3f3f3; /* Light grey 
    border-top: 10px solid #3498db; 
    border-radius: 50%;
    width: 100px;
    height: 100px;
    animation: spin 0.5s linear infinite; */
background: url('../../../images/loader.png');
width: 50px;
height: 50px;
animation: spin 0.5s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    12.5% { transform: rotate(45deg); }
    25% { transform: rotate(90deg); }
    37.5% { transform: rotate(135deg); }
    50% { transform: rotate(180deg); }
    62.5% { transform: rotate(225deg); }
    75% { transform: rotate(270deg); }
    87.5% { transform: rotate(315deg); }
    100% { transform: rotate(360deg); }
}