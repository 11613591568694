.tabs :global(.ant-tabs-nav) {
	margin-bottom: 0;
	color: #8090B8;
}

.tabs :global(.ant-tabs-nav)::before {
	border-bottom: 1px solid #E9EEF7;
}

.tabs :global(.ant-tabs-tab) {
	padding: 12px 24px;
	margin: 0 !important;
}

.filters {
	width: 100%;
	flex-wrap: wrap;
}

.filters>* {
	flex-basis: 150px;
	flex-grow: 1;
}

.filters>*>* {
	width: 100%;
}

.filters :global(.ant-select-selector) {
	height: 40px !important;
	padding: 5px 16px !important;
}

.verticalDescription :global(.ant-descriptions-item-container) {
	flex-direction: column;
}

.descriptions :global(.ant-descriptions-item-container) {
	align-items: baseline;
}

.table :global(.ant-table-row) {
	cursor: pointer;

}

.table :global(.ant-table-cell) {
	vertical-align: top;
	padding: 8px 0 8px 8px !important;
	font-size: 10px;
	font-style: normal;
	/* line-height: 16px !important; */
	color: #4B4F55 !important;
}

.table :global(.ant-table-cell):first-child {
	vertical-align: top;
	font-size: 10px;
	font-style: normal;
	/* line-height: 16px !important; */
	color: #4B4F55 !important;
	padding: 8px 0 8px 24px !important;
}

.table :global(.ant-table-cell)::before {
	width: 0 !important;
}

.table :global(.ant-table-thead) th {
	padding: 16px 0 0 8px !important;
	background-color: #FCFDFF !important;
	color: #8090B8 !important;
	text-transform: uppercase;
	font-weight: normal !important;
	border: none !important;
	font-size: 12px !important;
}

.table :global(.ant-table-thead) th:first-child {
	background-color: #FCFDFF !important;
	color: #8090B8 !important;
	text-transform: uppercase;
	font-weight: normal !important;
	padding: 16px 0 0 24px !important;
	font-size: 12px !important
}

.cellText {
	font-size: 10px;
}

.table :global(.ant-table-thead) tr {
	box-sizing: border-box;
	height: 50px;
}


.tableSmall :global(.ant-table-thead) {
	font-size: 12px !important;
}

.tableSmall :is(:global(.ant-table-tbody),
	:global(.ant-typography),
	:global(.ant-tag),
) {
	font-size: 12px;
}


.tableLoader {
	position: fixed !important;
}