.menu {
	width: 200px;
}

.menu ul {
	padding: 0 !important;
	border-radius: 4px !important;
	transform: translateY(8px);
}

.menu li {
	padding: 8px 12px !important;
	align-items: flex-start;
	gap: 8px;
	align-self: stretch;
	border-radius: 0 !important;
}

.menu svg {
	color: #6679A9 !important;
}

.menu li:last-child {
	border-top: 1px solid #E9EEF7;
}

.menu :global(.ant-dropdown-menu-item-active) {
	background-color: #F6F8FD !important;
}

.menu :global(.ant-dropdown-menu-item-active) svg {
	color: #327FEF !important;
}