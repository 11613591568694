.descriptions :global(.ant-descriptions-view) {
	border: none !important;
}

.descriptions :global(.ant-descriptions-item-label) {
	border: none !important;
}

.descriptions :global(.ant-descriptions-row):nth-child(2n) {
	background-color: #FCFDFF !important;
}

.passwordChangeInput :global(.ant-input){
	font-family: 'Monaco', Monaco, monospace !important;

}