.inputUrl :-webkit-autofill,
inputUrlt:-webkit-autofill:hover,
inputUrl:-webkit-autofill:focus,
inputUrl:-webkit-autofill:active {
	-webkit-box-shadow: 0 0 0 30px white inset !important;
}

.tabs :global(.ant-tabs-nav) {
	margin-bottom: 0;
	color: #8090B8;
}

.tabs :global(.ant-tabs-nav)::before {
	border-bottom: 1px solid #E9EEF7;
}

.tabs :global(.ant-tabs-tab) {
	padding: 12px 24px;
}

.table :global(.ant-table-cell) {
	vertical-align: top;
	padding: 8px 0 8px 8px !important;
	font-size: 10px;
	font-style: normal;
	/* line-height: 16px !important; */
	color: #4B4F55 !important;
	cursor: pointer;
}

.table :global(.ant-table-cell):first-child {
	vertical-align: top;
	font-size: 10px;
	font-style: normal;
	/* line-height: 16px !important; */
	color: #4B4F55 !important;
	padding: 8px 0 8px 24px !important;
}

.table :global(.ant-table-cell)::before {
	width: 0 !important;
}

.table :global(.ant-table-thead) th {
	padding: 16px 0 0 8px !important;
	background-color: #FCFDFF !important;
	color: #8090B8 !important;
	text-transform: uppercase;
	font-weight: normal !important;
	border: none !important;
	font-size: 12px !important;
}

.table :global(.ant-table-thead) th:first-child {
	background-color: #FCFDFF !important;
	color: #8090B8 !important;
	text-transform: uppercase;
	font-weight: normal !important;
	padding: 16px 0 0 24px !important;
	font-size: 12px !important
}

.table :global(.ant-table-column-sorters) {
	flex-direction: row-reverse !important;
}

.cellText {
	font-size: 10px;
}

.table :global(.ant-table-thead) tr {
	box-sizing: border-box;
	height: 50px;
}

.banner {
	background: transparent;
	height: 80px;
}

.bannerTitle {
	font-size: 24px;
	font-weight: 600;
}

/* .datepicker :global(.ant-picker-footer) {
	display: none!important;
	color: red;
} */
.gradient {
	width: 80%;
	height: 10px;
	background: linear-gradient(to right, yellow, #B00000);
	position: relative;
}

.triangle {
	width: 0;
	height: 0;
	opacity: .4;
	border-left: 7px solid transparent;
	border-right: 7px solid transparent;
	border-top: 15px solid grey;
}

.blur {
	filter: blur(6px);
}

.tableContainer {
	position: relative;
}

.kfeedTableLoader {
	position: absolute !important;
	top: 400% !important;
	transform: translate(-50%, -50%);
}

.tableLoader {
	position: fixed !important;
	top: 50% !important;
	transform: translate(-50%, -50%);
}