.inputUrl :-webkit-autofill,
inputUrlt:-webkit-autofill:hover,
inputUrl:-webkit-autofill:focus,
inputUrl:-webkit-autofill:active {
	-webkit-box-shadow: 0 0 0 30px white inset !important;
}

.tabs :global(.ant-tabs-nav) {
	margin-bottom: 0;
	color: #8090B8;
}

.tabs :global(.ant-tabs-nav)::before {
	border-bottom: 1px solid #E9EEF7;
}

.tabs :global(.ant-tabs-tab) {
	padding: 12px 24px;
}

.limitsCards {
	display: grid;
	gap: 6px;
	grid-template-columns: repeat(5, 1fr);
}

.limitsCards>div {
	padding: 20px 16px;
	background: #F6F8FD;
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	gap: 4px;
}

.limitsCards * {
	font-size: 16px;
}

.limitsCards>div> :first-child {
	color: #6679A9;
	text-transform: uppercase;
	font-size: 12px;
}

.settingsBtn {
	color: #327FEF;
	background-color: transparent;
	border-color:#327FEF;
	border-radius: 4px;
	font-size: 14px;
	height: 40px;
	width: 148px;
}

.settingsBtn:hover{
	color: #0047C7 !important;
	border-color: #0047C7 !important
}

.sandboxBtn{
	width:38px;
	height: 38px;
	z-index: 15;
	color: white;
	background-color:#2E496F;
	border: none;
	border-radius: 4px;

}

.sandboxBtn:hover{
	background-color:#233856 !important;
	color: white !important;
}

.sandboxSubmitBtn{
	width:100%;
	height: 44px;
	z-index: 15;
	color: white;
	background-color:#2E496F;
	border: none;
	border-radius: 4px;

}

.sandboxSubmitBtn:hover{
	background-color:#233856 !important;
	color: white !important;
}


.sandboxDeclineBtn{
	width:100%;
	height: 44px;
	z-index: 15;
	color: #2E496F;
	background-color:white;
	border: 1px solid #CED9EE;
	border-radius: 4px;

}

.sandboxDeclineBtn:hover{
	background-color:#F5F5F5 !important;
	color: #2E496F !important;
	border: 1px solid #CED9EE !important;
}

.graphsTabs :global(.ant-tabs-nav-wrap){
	display: flex;
	justify-content: flex-end;
	z-index:10;
}
.graphsTabs :global(.ant-tabs-tab){
	color: #8090B8;
}
.graphsTabs :global(.ant-tabs-tab:hover){
	color: black;
}
.graphsTabs :global(.ant-tabs-tab-active){
	-webkit-text-fill-color: black;
	/* color: black; */
	text-shadow: none;
}
.graphsTabs :global(.ant-tabs-ink-bar){
	background-color: black;
}
.graphsTabs :global(.ant-tabs-nav)::before{
	border-bottom: none;
}.graphsTabs :global(.ant-tabs-tab-btn:active){
	color: black !important;
}

.graphsTabs :global(.ant-tabs-tab-btn){
	color: #8090B8 !important;
}

.table :global(.ant-table-thead) th:first-child {
	background-color: #FCFDFF !important;
	color: #8090B8 !important;
	text-transform: uppercase;
	font-weight: normal !important;
	padding: 16px 0 0 24px !important;
	font-size: 12px !important
}

.table :global(.ant-table-thead) th:last-child {
	background-color: #FCFDFF !important;
	color: #8090B8 !important;
	text-transform: uppercase;
	font-weight: normal !important;
	padding: 16px 8px 0 26px !important;
	font-size: 12px !important
}

.table :global(.ant-table-thead) th {
	padding: 16px 0 0 26px !important;
	background-color: #FCFDFF !important;
	color: #8090B8 !important;
	text-transform: uppercase;
	font-weight: normal !important;
	border: none !important;
	font-size: 12px !important;
}

.table :global(.ant-table-cell) {
	vertical-align: top;
	padding: 8px 0 8px 26px !important;
	font-size: 13px;
	font-style: normal;
	/* line-height: 16px !important; */
	color: #4B4F55 !important;
	cursor: pointer;
}

.table :global(.ant-table-cell):first-child {
	vertical-align: top;
	font-style: normal;
	/* line-height: 16px !important; */
	color: #4B4F55 !important;
	padding: 8px 0 8px 24px !important;
}

.table :global(.ant-table-cell):last-child {
	vertical-align: top;
	font-style: normal;
	/* line-height: 16px !important; */
	color: #4B4F55 !important;
	padding: 8px 20px 8px 26px !important;
}

.table :global(.ant-table-cell)::before {
	width: 0 !important;
}


.mainTabs :global(.ant-tabs-nav-wrap){
	z-index:10;
}
.mainTabs :global(.ant-tabs-tab){
	color: #8090B8 !important;
	font-size: 16px
	
}
.mainTabs :global(.ant-tabs-tab:hover){
	color: black;
}
.mainTabs :global(.ant-tabs-tab-active){
	-webkit-text-fill-color: black;
	text-shadow: none;
}
.mainTabs :global(.ant-tabs-ink-bar){
	background-color: black;
}
.mainTabs :global(.ant-tabs-nav)::before{
	border-bottom: none;
}
.mainTabs :global(.ant-tabs-tab){
	color: black;
	font-weight: 400 !important
}
.mainTabs :global(.ant-tabs-tab-btn:active){
	color: black !important;
}

.mainTabs :global(.ant-tabs-tab-btn){
	color: #8090B8 !important;
}

.customProgress :global(.ant-progress-text ){
    color: #7A7D86 !important;
	font-weight: 500;
}

.tableLoader {
	position: fixed !important;
	top: 50% !important
  }
  