.chartjs-tooltip {
    display: flex;
    align-items: center;
}

.chartjs-tooltip .tooltip-color {
    width: 12px; 
    height: 12px; 
    border-radius: 50%; 
    margin-right: 8px; 
}